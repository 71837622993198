<div *ngIf="popupData"
     class="investments-popup"
     [class.is-mascot]="isMascot"
     [class.is-active]="popupData"
     [class.is-blurred]="false"
     [class.is-accent]="popupType === POPUP_TYPES.funds || popupType === POPUP_TYPES.cashApp">
    <div class="investments-popup__inner">
        <button class="investments-popup__close" (click)="closePopup()"></button>

        <ng-container *ngIf="isShowCashappCompliance() && showCashAppAttention">
          <div style="text-align: center;">
            <p class="nft-terms__text" style="margin-bottom: 1em; text-align: center">{{popupData.element.cashappCompliance.text}}</p>

            <button (click)="showCashAppAttention = false"
                    class="nft-info__btn snake-btn btn is-notouched">
              Continue
              <svg><rect></rect></svg>
            </button>
          </div>

        </ng-container>

        <ng-container *ngIf="!showCashAppAttention || !isShowCashappCompliance()">
          <div class="nft-info" *ngIf="(popupType !== POPUP_TYPES.funds) && (popupType !== POPUP_TYPES.cashApp)">
            <img class="nft-info__img"
                 [src]="popupData.element.image_link"
                 alt=""
                 *ngIf="popupType === POPUP_TYPES.info">
            <div class="nft-grid">
              <div class="nft-grid__col">
                <div class="nft-grid__cell">ID</div>
                <ng-container *ngFor="let item of popupData.element.tokens">
                  <div *ngIf="item.id==''">&nbsp;</div>
                  <div *ngIf="item.id!=''">
                    <a target="_blank" *ngIf="item.url; else noUrlTemplate" [href]="item.url" class="nft-grid__cell" >{{item.id}}</a>
                    <ng-template #noUrlTemplate>
                      <div class="nft-grid__cell">{{item.id}}</div>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
              <div class="nft-grid__col">
                <div class="nft-grid__cell">Address</div>
                <ng-container *ngFor="let item of popupData.element.tokens">
                  <div *ngIf="item.id==''">In progress</div>
                  <div *ngIf="item.id!=''">
                    <a target="_blank" *ngIf="item.url; else noUrlTemplate" [href]="item.url" class="nft-grid__cell" >{{item.address}}</a>
                    <ng-template #noUrlTemplate>
                      <div class="nft-grid__cell">{{item.address}}</div>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="nft-info" *ngIf="popupType === POPUP_TYPES.funds || popupType === POPUP_TYPES.cashApp">
            <div class="nft-info__head">
              <div class="nft-info__title">
                <ng-container *ngIf="popupType === POPUP_TYPES.funds">
                  {{popupData.element.cardInfo.description}} - {{popupData.element.cardInfo.number}}
                </ng-container>
                <ng-container *ngIf="popupType === POPUP_TYPES.cashApp">
                  CASH APP PAY
                </ng-container>
              </div>
              <div class="nft-info__title">
                {{popupData.element.label}}: {{popupData.element.amount | currency : 'USD' : 'symbol'}}
              </div>
            </div>

            <ng-template [ngIf]="!showTermsAndConditions">
              <img class="nft-info__img"
                   [src]="popupData.element.image"
                   alt="">
              <div class="nft-info__content">
                <div class="nft-info__title" *ngIf="!isPartnerTypeSingleBalance(); else senetTemplateHeader">
                  You are purchasing NFT
                </div>

                <ng-template #senetTemplateHeader>
                  <div class="nft-info__title" ngI>
                    You are minting NFT
                  </div>
                </ng-template>
                <div class="nft-info__row">
                  <div class="nft-info__col"><span *ngIf="!isPartnerTypeSingleBalance()">Purchase </span>Ownership</div>
                  <div class="nft-info__col">{{popupData.element.purchaseOwnership | number:'1.0-2'}}%</div>
                </div>
                <div class="nft-info__row">
                  <div class="nft-info__col">Total Ownership</div>
                  <div class="nft-info__col">{{popupData.element.totalOwnership | number:'1.0-2'}}%</div>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="showTermsAndConditions">
              <div class="nft-terms">
                <div class="nft-terms__title" *ngIf="!isPartnerTypeSingleBalance(); else senetTemplateTermsHeader">
                  ALL SALES ARE FINAL
                </div>

                <ng-template #senetTemplateTermsHeader>
                  <h2 class="nft-terms__title">ALL MINTING SALES ARE FINAL</h2>
                </ng-template>

                <p class="nft-terms__text">The nature of NFT does not allow returns and/or refunds. Your ownership will be published to the blockchain and is associated with your MATIC Wallet. Smart Contract governing your ownership rights allows you to transfer your NFT. The NFT License is described in terms and conditions below.</p>

                <h2 class="nft-terms__title">TERMS AND CONDITIONS</h2>
                <p class="nft-terms__text">1. Definitions.</p>
                <p class="nft-terms__text">“Art” means any art, design, and drawings that may be associated with an NFT that you Own.</p>
                <p class="nft-terms__text">"NFT" means any blockchain-tracked, non-fungible token, such as those conforming to the ERC-721 standard.</p>
                <p class="nft-terms__text">“Own” means (with respect to an NFT) an NFT that you have purchased or otherwise rightfully acquired from a legitimate source, where proof of such purchase is recorded on the relevant blockchain.</p>
                <p class="nft-terms__text">“Extensions” means third-party designs that: </p>
                <ul class="nft-terms__list">
                  <li class="nft-terms__list-item">are intended for use as extensions or overlays to the Art, </li>
                  <li class="nft-terms__list-item">do not themselves (directly) or by their presence (indirectly) modify the underlying Art, and</li>
                  <li class="nft-terms__list-item">can be removed at any time without affecting the underlying Art.</li>
                </ul>
                <p class="nft-terms__text">“Purchased NFT” means an NFT that you Own.</p>
                <p class="nft-terms__text">“Third-Party IP” means any third-party patent rights (including, without limitation, patent applications and disclosures), copyrights, trade secrets, trademarks, know-how’s, or any other intellectual property right recognized in any country or jurisdiction in the world and beyond.</p>

                <p class="nft-terms__text">2. Ownership.</p>
                <p class="nft-terms__text">You acknowledge and agree that Creator (or, as applicable, its licensors) owns all legal right, title, and interest in and to the Art, and all intellectual property rights therein. </p>
                <p class="nft-terms__text">The rights that you have in and to the Art are limited to those described in this License. Creator reserves all rights in and to the Art not expressly granted to you in this License.</p>

                <p class="nft-terms__text">3. License.</p>
                <p class="nft-terms__text">General Use. Subject to your continued compliance with the terms of this License, Creator grants you a worldwide, non-exclusive, non-transferable, royalty-free license to use, copy, and display the Art for your Purchased NFT’s, along with any Extension that you choose to create or use, solely for the following purposes:</p>
                <ul class="nft-terms__list">
                  <li class="nft-terms__list-item">for your own personal, non-commercial use;</li>
                  <li class="nft-terms__list-item">as part of a marketplace that permits the purchase and sale of your NFT’s, provided that the marketplace cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFT’s to ensure that only the actual owner can display the Art; or,</li>
                  <li class="nft-terms__list-item">as part of a third-party website or application that permits the inclusion, involvement, or participation of your NFT’s, provided that the website/application cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFT’s to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased NFT leaves the website/application.</li>
                </ul>
                <p class="nft-terms__text">Commercial Use. Subject to your continued compliance with the terms of this License, Creator grants you a limited, worldwide, non-exclusive, non-transferable license to use, copy, and display the Art for your Purchased NFT’s for the purpose of commercializing your own merchandise that includes, contains, or consists of the Art for your Purchased NFT’s (“Commercial Use”), provided that such Commercial Use does not result in you earning more than One Hundred Thousand Dollars ($100,000) in gross revenue each year. For the sake of clarity, nothing in this Section 3.b will be deemed to restrict you from </p>
                <ul class="nft-terms__list">
                  <li class="nft-terms__list-item">owning or operating a marketplace that permits the use and sale of NFT’s generally, provided that the marketplace cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFT’s to ensure that only the actual owner can display the Art;</li>
                  <li class="nft-terms__list-item">owning or operating a third-party website or application that permits the inclusion, involvement, or participation of NFT’s generally, provided that the third-party website or application cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFT’s to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased NFT leaves the website/application; or,</li>
                  <li class="nft-terms__list-item">earning revenue from any of the foregoing, even where such revenue is in excess of One Hundred Thousand Dollars ($100,000) per year.</li>
                </ul>

                <p class="nft-terms__text">4. Restrictions.</p>
                <p class="nft-terms__text">You agree that you may not, nor permit any third-party to do or attempt to do any of the foregoing without Creator’s express prior written consent in each case:</p>
                <ul class="nft-terms__list">
                  <li class="nft-terms__list-item">modify the Art for your Purchased NFT in any way, including, without limitation, the shapes, designs, drawings, attributes, or color schemes (your use of Extensions will not constitute a prohibited modification hereunder);</li>
                  <li class="nft-terms__list-item">use the Art for your Purchased NFT’s to advertise, market, or sell any third-party product or service;</li>
                  <li class="nft-terms__list-item">use the Art for your Purchased NFT’s in connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the rights of others;</li>
                  <li class="nft-terms__list-item">use the Art for your Purchased NFT’s in movies, videos, or any other forms of media, except to the limited extent that such use is expressly permitted in Section (3)(b) above or solely for your own personal, non-commercial use;</li>
                  <li class="nft-terms__list-item">sell, distribute for commercial gain (including, without limitation, giving away in the hopes of eventual commercial gain), or otherwise commercialize merchandise that includes, contains, or consists of the Art for your Purchased NFT’s, except as expressly permitted in Section (3)(b) above;</li>
                  <li class="nft-terms__list-item">attempt to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the Art for your Purchased NFTs; or,</li>
                  <li class="nft-terms__list-item">otherwise utilize your Purchased NFT’s for your or any third-party’s commercial benefit.</li>
                </ul>
                <p class="nft-terms__text">Furthermore, to the extent that Art associated with your Purchased NFT’s contains Third Party IP (e.g., licensed intellectual property from a celebrity, athlete, or other public figure), you understand and agree as follows: </p>
                <ul class="nft-terms__list">
                  <li class="nft-terms__list-item">that you will not have the right to use such Third-Party IP in any way except as incorporated in the Art, and subject to the license and restrictions contained herein;</li>
                  <li class="nft-terms__list-item">that the Commercial Use license in Section (3)(b) above will not apply;</li>
                  <li class="nft-terms__list-item">that, depending on the nature of the license granted from the owner of the Third-Party IP, Creator may need to pass through additional restrictions on your ability to use the Art; and,</li>
                  <li class="nft-terms__list-item">that Creator informs you of such additional restrictions in writing (email is permissible), you will be responsible for complying with all such restrictions from the date that you receive the notice, and that failure to do so will be deemed a breach of this license. The restriction in Section 4 will survive the expiration or termination of this License.</li>
                </ul>

                <p class="nft-terms__text">5. Terms of License.</p>
                <p class="nft-terms__text">The license granted in Section 3 above applies only to the extent that you continue to Own the applicable Purchased NFT. If at any time you sell, trade, donate, give away, transfer, or otherwise dispose of your Purchased NFT for any reason, the license granted in Section 3 will immediately expire with respect to those NFT’s without the requirement of notice, and you will have no further rights in or to the Art for those NFT’s. If you exceed the One Hundred Thousand Dollars ($100,000) limitation on annual gross revenue set forth in Section (3)(b) above, you will be in breach of this License, and must send an email to Creator at support@iriskeysolutions.com  within fifteen (15) days, with the phrase “NFT License - Commercial Use” in the subject line, requesting a discussion with Creator regarding entering into a broader license agreement or obtaining an exemption (which may be granted or withheld in Creator’s sole and absolute discretion). If you exceed the scope of the license grant in Section (3)(b) without entering into a broader license agreement with or obtaining an exemption from Creator, you acknowledge and agree that:</p>
                <ul class="nft-terms__list">
                  <li class="nft-terms__list-item">you are in breach of this License;</li>
                  <li class="nft-terms__list-item">in addition to any remedies that may be available to Creator at law or in equity, the Creator may immediately terminate this License, without the requirement of notice; and,</li>
                  <li class="nft-terms__list-item">you will be responsible to reimburse Creator for any costs and expenses incurred by Creator during the course of enforcing the terms of this License against you.</li>
                </ul>
              </div>

              <div class="nft-terms-agreement">
                <input #termsAccept id="termsAccept" checked="checked" class="nft-terms-agreement__input checkbox-primary" type="checkbox">
                <label class="nft-terms-agreement__label" for="termsAccept">I Accept</label>
              </div>

            </ng-template>


            <div class="nft-info__btns">
              <button *ngIf="!showTermsAndConditions && !isMascot" (click)="onShowTermsAndConditions()" class="nft-info__btn snake-btn btn is-notouched">
                Continue
                <svg><rect></rect></svg>
              </button>

              <button *ngIf="!showTermsAndConditions && isMascot" (click)="onShowTermsAndConditions()" class="nft-info__btn--mascot">
              </button>

              <button *ngIf="showTermsAndConditions && !isMascot && (popupType !== POPUP_TYPES.cashApp)"
                      (click)="onDepositConfirm()"
                      class="nft-info__btn snake-btn btn is-notouched"
                      [disabled]="checkTermsAgreeUncheck()">
                Buy
                <svg><rect></rect></svg>
              </button>

              <button *ngIf="showTermsAndConditions && isMascot" (click)="onDepositConfirm()" [disabled]="checkTermsAgreeUncheck()" class="nft-info__btn--mascot-ok">
              </button>

              <div id="payment-form" style="width: 100%" *ngIf="showTermsAndConditions && (popupType === POPUP_TYPES.cashApp)">
                <div id="cash-app-pay"></div>
              </div>
            </div>
          </div>
        </ng-container>

    </div>
</div>
