export const DOMAIN_DEMO_NAME = 'user.dev.eboxenterprises.com';
export const DOMAIN_DEMO1_NAME = 'demo.dev.eboxenterprises.com';
export const DOMAIN_DEMO2_NAME = 'demo1.dev.eboxenterprises.com';
export const DOMAIN_DEMO3_NAME = 'jr.eboxenterprises.com';
export const DOMAIN_DEMO4_NAME = 'river.dev.studionovi.co';
export const DOMAIN_DEMO5_NAME = 'mascot.demo1.iriskeysolutions.com';
export const DOMAIN_DEMO6_NAME = 'demo1.mascot.eboxenterprises.com';
export const DOMAIN_DEMO7_NAME = 'demo1.dev.mascot.eboxenterprises.com';
export const DOMAIN_DEMO8_NAME = 'play.dev.sneakapic.io';
export const DOMAIN_DEMO9_NAME = 'label.dev.eboxenterprises.com';
export const DOMAIN_DEMO_NAME_API = '148.113.193.136:20443';
export const DOMAIN_DEMO_API = 'http://'+DOMAIN_DEMO_NAME_API;

export const DOMAIN_INTERIM_NAME = 'user.studionovi.com';
export const DOMAIN_INTERIM_NAME_MASCOT = 'user.mascot.studionovi.com';
export const DOMAIN_INTERIM_NAME_MASCOT1 = 'user-mascot.studionovi.com';
export const DOMAIN_INTERIM_NAME_TERMINAL = 'terminal.studionovi.com';
export const DOMAIN_INTERIM_NAME_API = 'api.studionovi.com';
export const DOMAIN_INTERIM_API = 'https://'+DOMAIN_INTERIM_NAME_API;
