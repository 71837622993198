import {HERO_VERSIONS} from "./hero_versions.model";
import {RouterPaths} from "./router.paths.model";
import {
    DOMAIN_DEMO1_NAME,
    DOMAIN_DEMO2_NAME,
    DOMAIN_DEMO3_NAME,
    DOMAIN_DEMO4_NAME,
    DOMAIN_DEMO5_NAME,
    DOMAIN_DEMO6_NAME,
    DOMAIN_DEMO7_NAME,
    DOMAIN_DEMO8_NAME,
    DOMAIN_DEMO9_NAME,
    DOMAIN_DEMO_NAME
} from "./demo_settings";

export const PROJECT_CONFIG = {
    defaultAvatar: '../../../../assets/images/default_user.png',
    defaultWomanAvatar: '../../../../assets/images/woman-avatar.jpg',
    defaultImage: '../../../../assets/images/default_image.png',
    defaultImage2: '../../../../assets/images/default/no-image.jpeg',
    defaultCryptoIcon: 'cryptocurrency.png',
    transactionsTypeDebit: '1',
    transactionsTypeCredit: '2',
    postPaymentTypeFree: 1,
    postPaymentTypePay: 0,
    paymentMethodCard: 1,
    paymentMethodPaypal: 2,
    defaultDomain: 'user.eboxenterprises.com',
    pointSubdomainEbox: 'user',
    pointSubdomainEboxDemo: 'user.dev',

    terminalMinAmount: 20,
    terminalPaymentType: {
        crypto: 1,
        sweeps: 2
    },
    heroVersion: HERO_VERSIONS.crypto,
    // heroVersion: HERO_VERSIONS.watch,
};

export const HERO_TEXT = {
    default: 'BECOME A MEMBER BUY & LEND BITCOINS FREE SWEEPSTAKES',
    riverpay: 'RiverPay sweeps'
}

export const PROJECT_MESSAGES = {
    unknownError: 'Sorry there was an error try again later.',
    notAllFieldsAreFilled: 'Please, fill all required fields.',
    incorrectDataFormat: 'Incorrect data format.',
    askLoginForTrade: 'Please login to start traiding.'
}

export const PROJECT_CLASSES = {
    hidden: 'is-hidden'
}

export const PROVIDERS_GAME_STATUS = {
    notAvailable: 2
}

export const RIVERPAY_SLIDER_LINK = {
    dev: 'https://river.slider.dev.eboxenterprises.com',
    prod: 'https://river.slider.eboxenterprises.com',
}

export const PROVIDERS_TEMPLATES = {
    mascotSingle: 'mascotSingle'
}

export const TIMERS_CONFIG = {
    popupHideAfter: 5000
}

export const PAGES_WITHOUT_LOGIN = [
  RouterPaths.transactionProcessing,
  RouterPaths.massSms,
  RouterPaths.promo777,
  RouterPaths.senetpro
]

export const SENETPRO_URL = window.location.hostname == DOMAIN_DEMO_NAME || window.location.hostname == DOMAIN_DEMO1_NAME ||
                            window.location.hostname == DOMAIN_DEMO2_NAME || window.location.hostname == DOMAIN_DEMO3_NAME ||
                            window.location.hostname == DOMAIN_DEMO4_NAME ||window.location.hostname == DOMAIN_DEMO5_NAME ||
                            window.location.hostname == DOMAIN_DEMO6_NAME ||window.location.hostname == DOMAIN_DEMO7_NAME ||
                            window.location.hostname == DOMAIN_DEMO8_NAME ||window.location.hostname == DOMAIN_DEMO9_NAME ?
                            'http://gifts.dev.eboxenterprises.com' : 'https://senetpro.com';
